import React from 'react'
import {useState} from "react"
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';
import ClientsData from "../conf/clients_logos.json"
import ClientSlide from './client_slide';

const clients_slider_settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  variableWith: false
};

export default function ClientSlider() {
    const [activeLanguage, setActiveLanguage] = useState()
    const { t } = useTranslation(['main']);

    let clients_slides = []
    for (let client in ClientsData){
      if (ClientsData[client]["active"]){
        clients_slides.push(<ClientSlide
          client_name={ClientsData[client]["name"]}
          active={ClientsData[client]["active"]}
          url={ClientsData[client]['url']}
          target={ClientsData[client]['url_target']}
          logo={ClientsData[client]['logo']}
          t={t}
          title={t('main:testimonials_meta_seo-title_txt')}
          />)
        }
    }

    return(
        <>
        <div className="container">
            <h1 className="sec-title"> {t('title_our_clients' )} </h1>
            <div className="ref-slider">
            <div className="slider-block responsive-two">
              <Slider {...clients_slider_settings}>
                {clients_slides}
              </Slider>
            </div>

            </div>
          </div>
        </>
        )

    }
