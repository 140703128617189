import React from 'react'

export default function ClientSlide(props) {
    return(
        <>
            <div className="home-reference-row">
                <a href={props.url} target={props.target} title={props.t('main:our_clients_link_title',{clientname: props.client_name})} border="0">
                    <img src={`/logos/${props.logo}`} />
                </a>
            </div>
        </>
    )
}